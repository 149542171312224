import React from 'react';
import { Dialog, ViewTable, Layout, Button, Filter } from '@/components';
import { routerMap } from '@/router/agent_router';
import apis from '@/api/api';
import { Link } from 'react-router-dom';
import CouponGiveStatus from '../components/CouponGiveStatus';
import { LibAuthCode } from 'lib/Auth';
import LibUtils from 'lib/utils';
import LibEnum from 'lib/enum';

export default class CouponFullMinusPage extends React.PureComponent<RouterPropsType> {

  private table = React.createRef<ViewTable>()
  
  private queryParams = {}

  render() {
  
    return <Layout.Page>
      <Layout.Toolbar title="满减券">
        <Button authCode={LibAuthCode.coupon.create} type="primary" onClick={() => {
          this.props.history.push(routerMap['coupon.full-minus.create'].path);
        }}>新增满减券</Button>
        <Button.Refresh onClick={() => {
          this.table.current?.refresh();
        }}/>
      </Layout.Toolbar>

      <Filter
        fields={[
          {
            type: 'select',
            name: '优惠券类型',
            field: 'couponType',
            options: LibUtils.enumToOptions(LibEnum.COUPON_TYPE)
          },
          {
            type: 'input',
            field: 'keyword',
            name: '搜索',
            placeholder: '优惠券名称'
          },
        ]}
        onChange={values => {
          this.queryParams = values;
          this.table.current?.reset(this.queryParams);
        }}
      />

      <ViewTable
        flex1
        ref={this.table}
        rowKey={row => row.id}
        fetchOption={{
          api: apis['coupon.findAndCount'],
        }}
        actionButtons={[
          {
            type: 'detail',
            onClick: row => {
              this.props.history.push(routerMap['coupon.detail'].getPath(row.couponId));
            }
          },
          {
            type: "done",
            authCode: LibAuthCode.coupon.update,
            disabled: row => row.giveStatus === 'DONE',
            onClick: (row) => {
              this.clickDone(row.couponId, () => {
                this.table.current?.refresh();
              });
            }
          },
        ]}
        columns={[
          {
            title: '编号',
            dataIndex: 'id',
            width: 160,
            align: 'center'
          },
          {
            title: '优惠券名称',
            dataIndex: 'couponName',
            width: 200,
            render: (v, row) => {
              return <Link to={routerMap['coupon.detail'].getPath(row.id)}>{v}</Link>
            }
          },
          {
            title: '发放状态',
            dataIndex: 'giveStatus',
            width: 150,
            align: 'center',
            render: (v) => {
              return <CouponGiveStatus status={v}/>
            }
          },
          {
            title: '优惠券类型',
            dataIndex: 'couponTypeDesc',
            width: 150,
            align: 'center'
          },
          {
            title: '适用范围',
            dataIndex: 'scopeDesc',
            width: 300,
          },
          {
            title: '满足金额',
            dataIndex: 'fullAmount',
            align: 'right',
            width: 100,
          },
          {
            title: '减去金额',
            dataIndex: 'minusAmount',
            align: 'right',
            width: 100,
          },
          // {
          //   title: '使用说明',
          //   dataIndex: 'couponDesc',
          //   width: 200,
          // },
          {
            title: '领取方式',
            dataIndex: 'limitGetCount',
            align: 'center',
            width: 100,
            render: (v) => {
              return v ? ('每人限领 ' + v + '')  : '无限制';
            },
          },
          {
            title: '计划发放',
            dataIndex: 'limitGiveCount',
            align: 'center',
            width: 100,
            render: (v) => {
              return v || '无限制';
            },
          },
          {
            title: '已发放',
            dataIndex: 'givenCount',
            align: 'center',
            width: 100,
          },
          {
            title: '有效期',
            width: 240,
            align: 'center',
            render: (value, row) => {
              return `${row.validityStartDate} 至 ${row.validityEndDate}`;
            },
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createdAt',
            width: 200,
          },
        ]}
      />

    </Layout.Page>
  }

  public refresh = () => {
    this.table.current?.reset();
  }

  private clickDone = (couponId: string, callback: Function) => {
    Dialog.confirm({
      title: '结束确认',
      content: '确定结束发放该优惠券吗？',
      onOk: async () => {
        apis['coupon.done'].post({
          couponId: couponId,
        })
        .then(() => {
          showSuccess.save();
          callback();
        })
      },
    });
  }

  private clickDelete = (couponId: string, callback: Function) => {
    Dialog.confirm({
      title: '删除确认',
      content: '确定删除该优惠券吗？',
      onOk: async () => {
        apis['coupon.delete'].post({
          couponId: couponId,
        })
        .then(() => {
          showSuccess.delete();
          callback();
        })
      },
    });
  }
}